import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import {
  cvContainer,
  infoContainer,
  embededContainer,
} from "../sass/cv.module.scss"

const CV = ({ data: allContentfulCvPage }) => {
  const {
    disclaimer,
    cv,
  } = allContentfulCvPage.allContentfulCvPage.edges[0].node

  return (
    <Layout>
      <SEO title="Guillaume Bouffard | CV" />
      <div className={cvContainer}>
        <div className={infoContainer}>{disclaimer.disclaimer}</div>
        <embed
          src={cv.file.url}
          className={embededContainer}
          type="application/pdf"
        ></embed>
      </div>
    </Layout>
  )
}

export default CV

export const query = graphql`
  query cvPageQuery {
    allContentfulCvPage {
      edges {
        node {
          disclaimer {
            disclaimer
          }
          cv {
            file {
              url
            }
          }
        }
      }
    }
  }
`
